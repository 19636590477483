@import '@/styles/functions';
@import '@/styles/new-variables';

.subscriptionModalBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  .paymentTabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    .paymentsWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .paymentSwitchers {
      display: flex;

      & > li {
        flex: 1 1;
        display: flex;
        & > button {
          flex: 1 1;
        }
      }
    }
  }
}

.tariffsCard {
  overflow-x: hidden;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .tariffsCardWrapper {
    position: relative;
    padding: 20px;
    border-radius: 0px 20px 20px 0px;
    border: 1px solid rgba(255, 255, 255, 0.06);
    background: $black;
    backdrop-filter: blur(15px);
  }
}
