@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/new-variables';

.tagsList {
  padding-top: toRem(12);
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  gap: 1rem;

  .tagElement {
    min-width: 45%;
    display: flex;
    align-items: center;
    gap: toRem(8);

    .iconWrapper {
      display: inline-flex;
      padding: toRem(6);
      justify-content: center;
      align-items: center;
      border-radius: toRem(5.854);
      color: $primary;

      &.primary {
        background: var(--transparrent-yellow-yellow-10, rgba(255, 255, 77, 0.1));
        backdrop-filter: blur(7.317073345184326px);
      }

      &.secondary {
        background: var(--transparrent-white-white-6, rgba(255, 255, 255, 0.06));
        backdrop-filter: blur(7.317073345184326px);
      }
    }

    .tagsInfo {
      h5 {
        color: $white;
        font-size: toRem(12);
        font-weight: 700;
        line-height: 130%; /* 15.6px */
      }

      p {
        color: $transparent-white-50;
        font-size: 12px;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
      }
    }
  }
}
