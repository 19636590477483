@import '@/styles/functions';
@import '@/styles/new-variables';

.subcscriptionModal {
  @media (min-width: 1200px) {
    transform: translate(-85%, -50%);
  }

  .subcscriptionModalHeader {
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid $transparent-white-6;
  }
}

.authModalBody {
  padding: toRem(20);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: toRem(20);

  @media (min-width: 400px) {
    padding: 0.9rem;
  }
  @media (max-width: get-breakpoint-width('md')) {
    grid-template-columns: 1fr;
  }
}

.subscriptionModalBody {
  max-height: 80vh;
  overflow-y: auto;
  padding: toRem(20);
  gap: toRem(20);

  @media (min-width: 1200px) {
    height: toRem(575);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.subscriptionModalHeader {
  border-bottom: 1px solid $transparent-white-6;
}
