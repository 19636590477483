@import "@/styles/functions";
@import "@/styles/new-variables";

.bankCard {
  position: relative;
  width: 100%;
  padding: toRem(12);
  border-radius: toRem(12);
  background: $grey;
  display: flex;
  align-items: center;
  gap: toRem(12);
  background-color: $grey-1;

  .iconWrapper {
    width: toRem(40);
    height: toRem(40);
    display: flex;
    padding: toRem(8);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: toRem(8);
    background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
  }

  .bankCardInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: toRem(5);
  }

  .userInfo {
    color: $transparent-white-30;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }

  .number {
    color: $white;
    font-size: 15px;
    font-weight: 600;
    line-height: 130%; /* 19.5px */
  }
}

.trashbinIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: toRem(12);
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $red;
  }
}
