@import '@/styles/variables';
@import '@/styles/functions';

.subscriptionForm {
  margin: 0;

  @media (min-width: 1200px) {
    width: toRem(382);
  }

  .description {
    color: $light-dimmed-color-3;
    line-height: 1.094rem;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .title {
    color: $light-dimmed-color-3;
    line-height: 1.563rem;

    @media (min-width: 470px) {
      font-size: 1.1rem;
    }
  }

  .subscriptionFormBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.5rem;
  }

  .alert {
    margin-top: 0;
  }
}

.errorMessage {
  padding: 0.25rem 0 0;
}

.formFooter {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1rem;
}

.link {
  font-size: 1rem;
  color: $solid-button-signature-bg-color;
  margin-right: 0.5rem;
}
.inputGroup {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media (min-width: 400px) {
    grid-template-columns: 2.5fr 1.5fr;
  }
}

.customSwiperSlide {
  min-height: 70px;
  overflow: visible;
  ul {
    li {
      height: auto;
    }
  }
}

.footerButtonGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 400px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  button {
    display: flex;
    align-items: center;
  }
}

.subscriptionWrapper {
  padding: 3px;
  margin: -3px;
  overflow-x: auto;
}

.subscription {
  display: flex;
  overflow-x: auto;
  cursor: pointer;
  gap: 1rem;
  padding-bottom: 0.2rem;
}

.swiperSlide {
  width: 12.5rem;
}

.balance {
  flex-direction: row;
  align-items: center;

  span {
    margin-bottom: 0.3rem;
  }
}

.placeholderRow {
  display: flex;
  overflow: hidden;
}
