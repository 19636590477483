@import "@/styles/variables";
@import "@/styles/functions";
@import "@/styles/new-variables";

.subscriptionCard {
  width: 100%;
  height: toRem(162);
  position: relative;
  border-radius: toRem(11.876);
  padding: 1rem;
  overflow: hidden;
  user-select: none;

  .backgroundImage {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.secondary {
    border: 0.742px solid $transparent-white-6;
    background: $grey-1;
  }

  &.primary {
    border: toRem(0.732) solid rgba(255, 255, 77, 0.05);
    background: linear-gradient(100deg, rgba(255, 255, 77, 0.1) -36.69%, rgba(244, 102, 0, 0.1) 102.99%);
  }

  &.default {
    background-color: $dark-color-3;
  }

  .subcscriptionMainHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: toRem(12);
    min-height: toRem(45);

    .headerLeft {
      display: flex;
      align-items: center;
      gap: toRem(8);

      & > svg {
        align-self: flex-start;
      }

      & > .title {
        font-size: toRem(20);
        font-weight: 700;
        line-height: 130%; /* 23.4px */
        text-transform: uppercase;

        &.secondary {
          background: var(--Main-Gradient-for-title, linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.primary {
          background: linear-gradient(135deg, #ffff4d -29.15%, #f46600 150.71%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .subcscriptionTagsList {
    padding-top: 0;
    gap: 1rem 0;
  }

  .description {
    color: var(--transparrent-white-white-50, rgba(255, 255, 255, 0.5));
    font-size: toRem(11);
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
}

.subscriptionCardSkelton {
  width: 100%;
  height: toRem(162);
  position: relative;
  border-radius: toRem(11.876);
  padding: 1rem;
  overflow: hidden;
}
