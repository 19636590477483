@import "@/styles/functions";
@import "@/styles/new-variables";

.verificationBanner {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 20px);
    height: 100%;
    overflow: hidden;
    border-radius: toRem(20);

    @media (max-width: get-breakpoint-width("md")) {
        display: none;
    }
}

.phones {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 85%;
    overflow: hidden;
    border-radius: toRem(20);
}

.content {
    position: absolute;
    bottom: toRem(40);
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;

    .apps {
        display: flex;
        justify-content: center;
        gap: toRem(16);
        margin-bottom: toRem(16);

        .app {
            border-radius: 6px;
            border: 0.842px solid var(--Greys-Grey-4, #878788);
            background: var(--Greys-Grey-1, #19191b);
            height: toRem(36);
            width: toRem(160);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: toRem(8);
            padding: toRem(5);

            .appTitle {
                color: white;
                font-size: 0.75rem;
                font-weight: 700;
                line-height: 15.158px;
            }
            .appText {
                color: var(--greys-grey-5-text, #c5c5c5);
                font-size: toRem(8);
                line-height: 140%;
            }
        }
    }

    .title {
        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 130%;
    }
    .subtitle {
        text-align: center;
        color: var(--Greys-Grey-4, #878788);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 140%;
    }
}
