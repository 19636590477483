@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/new-variables';

.subscriptionInfo {
  .title {
    color: $white;
    text-align: center;
    font-size: toRem(24);
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
  }

  .description {
    color: $transparent-white-30;
    text-align: center;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    margin-bottom: toRem(20);
  }

  .subscriptionList {
    display: flex;
    flex-direction: column;
  }
  .resultSubscriptionList {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: $grey-1;

    & > li {
      padding: 8px 12px;
    }
  }
}
.subscriptionInfoLine {
  display: flex;
  padding: toRem(4) 0px;
  align-items: baseline;
  gap: toRem(4);
  align-self: stretch;

  & > .value {
    display: flex;
    align-items: center;
    color: $white;
    text-align: right;
    font-style: normal;
    line-height: 130%; /* 15.6px */

    &.sm {
      font-size: toRem(12);
      font-weight: 500;
    }

    &.md {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  & > .line {
    flex: 1;
    width: 100%;
    border-bottom: 1px dashed $transparent-white-15;
  }
}

.label {
  display: flex;
  align-items: center;
  color: $transparent-white-50;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */

  &.sm {
    font-size: toRem(12);
  }

  &.md {
    font-size: toRem(14);
  }

  &.active {
    color: $alert-green;
  }
}

.lineRow {
  width: 100%;
  height: 1px;
  background: $transparent-white-10;
}

.autoRenew {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: toRem(8);
  color: $grey-4;
  font-size: torem(14);
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
}
