@import '@/styles/variables';

.hasNotCardWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > form {
    height: 100%;
    margin: 0;
  }

  .addCardWrapper {
    height: 100%;
  }

  .addCardButton {
    width: 100%;
  }
}
